﻿// Global variables that define default values and Boostrap variable overrides for the vanilla site live here
// Every variable should have !default set so they can be overidden in _variables-client.scss
$spacer: 1.2rem !default;

// Grays
$white: #fff !default;
$gray-100: #e1e2e4 !default;
$gray-200: #c3c6ca !default;
$gray-300: #a6a9af !default;
$gray-400: #888d95 !default;
$gray-500: #6a707a !default;
$gray-600: #555a62 !default;
$gray-700: #404349 !default;
$gray-800: #2a2d31 !default;
$gray-900: #151618 !default;
$black: #000 !default;
$color-lightest-gray: #f6f6f7 !default;

// Brand colours
$brand--green: #3c9500;
$brand-gray: $gray-800 !default;
$brand--purple: #441a7a;

// Override Bootstrap colors
$red: #b30415 !default;
$orange: #ffa500 !default;
$blue: #1e96f1 !default;

$green: $brand--green !default;
$primary: $brand--green !default;
$secondary: $brand-gray !default;

$success: $green !default;
$warning: $orange !default;
$danger: $red !default;

// Body
//
// Settings for the `<body>` element.
$body-bg: var(--body-bg, $color-lightest-gray) !default;
$body-color: var(--body-color, $gray-800) !default;

// Typography
$custom-fonts-url: "https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap" !default;
$font-family-sans-serif: "Source Sans Pro", sans-serif !default;
$font-size-base: 1.2rem !default;

$line-height-base: 1.5 !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$font-size-sm: $font-size-base !default;
$font-size-lg: $font-size-base !default;
$font-size-md: $font-size-base !default;
$font-size-xl: $font-size-base !default;

$line-height-sm: $line-height-base !default;
$line-height-lg: $line-height-base !default;
$line-height-md: $line-height-base !default;
$line-height-xl: $line-height-base !default;

$headings-font-family: null !default;
$headings-font-weight: 700 !default;
$headings-color: null !default;
$headings-letter-spacing: inherit !default;

$page-title-font-family: $headings-font-family !default;
$page-title-font-size: null !default;
$page-title-color: $headings-color !default;

// A variable to fix the alignment of the 'Fira' font. Leave as false by default
$text-alignment-rule: false !default;

// Header
//
// Settings for the Header area
$top-bar-text-transform: inherit !default;
$top-bar-bg: var(--top-bar-bg, $color-lightest-gray) !default;
$top-bar-color: var(--top-bar-color, inherit) !default;
$top-bar-link-color: var(--top-bar-color, inherit) !default;
$top-bar-link-hover-color: inherit !default;
$top-bar-text-decoration: none !default;
$top-bar-text-decoration-hover: underline !default;
$top-bar-border-bottom: none !default;
$top-bar-icon-color: inherit !default;
$top-bar-user-greeting-decoration: underline !default;
$top-bar-user-greeting-decoration-hover: none !default;
// Header
$header-bg: var(--header-bg, $white) !default;
$header-bg-2: var(--header-bg-2, $white) !default;
$header-title-color: var(--header-title-color, inherit) !default;
$header-title-font-family: $headings-font-family !default;
$header-title-font-size: null !default;

$header-border-bottom: solid 1px $gray-100 !default;
$header-shadow: true !default;
// logo
$header-logo-width: 150px !default;
$header-logo-height: 75px !default;
// login bar
$login-bar-text-transform: uppercase !default;
$login-bar-bg: var(--login-bar-bg, $color-lightest-gray) !default;
$login-bar-color: var(--login-bar-color, inherit) !default;
$login-bar-link-color: inherit !default;
$login-bar-link-hover-color: inherit !default;
$login-bar-border-bottom: solid 1px $gray-100 !default;
$login-bar-icon-color: inherit !default;
// popover
$popover-arrow-color: $gray-100 !default;
// basket icon
$basket-icon-color: var(--basket-icon-color, inherit) !default;
$basket-icon-color-hover: var(--basket-icon-color-hover, inherit) !default;
// basket badge
$basket-badge-background: var(--basket-badge-bg, $secondary) !default;
$basket-badge-color: var(--basket-badge-color, $white) !default;
$basket-badge-padding: 1px 2px 2px 2px !default;
// basket notification
$basket-notification-bg: $secondary !default;
$basket-notification-color: $white !default;
// countdown notification
$countdown-notification-bg: var(--countdown-bg, $secondary) !default;
$countdown-notification-color: var(--countdown-color, $white) !default;

// Footer
//
// Settings for the Footer area
$footer-bg: var(--footer-bg, $white) !default;
$footer-color: var(--footer-color, $gray-900) !default;
$footer-border-top: solid 1px $gray-300 !default;
$footer-bar-bg: var(--footer-bar-bg, $color-lightest-gray) !default;
$footer-bar-color: var(--footer-bar-color, $gray-900) !default;
$footer-link-color: $footer-color !default;
$footer-link-text-decoration: underline !default;
$footer-link-hover-color: $footer-color !default;
$footer-link-decoration-hover: none !default;
$footer-bar-logo-mw: 100px !default;
$footer-bar-text-transform: inherit !default;

// Links
$link-color: $secondary !default;
$link-decoration: underline !default;
$link-hover-color: darken($link-color, 35%) !default;
$link-hover-decoration: none !default;

// Components
//
// Define common padding and border radius sizes and more.
$border-width: 1px !default;
$border-color: white !default;
$border-radius: 0.8rem !default;
$border-radius-lg: $border-radius !default;
$border-radius-sm: 0.6rem !default;

// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-padding-y: 0.375rem !default;
$input-btn-padding-x: 0.75rem !default;

// Calendar
$calendar-background-color: transparent !default;
$calendar-color: $gray-900 !default;
$calendar-font-weight: bold !default;
$calendar-disabled-color: lighten($calendar-color, 70%) !default;
$calendar-border: false !default;
$calendar-border-color: $gray-400 !default;
$calendar-selected-background-color: $black !default;
$calendar-selected-border-color: $black !default;
$calendar-selected-color: $white !default;
$calendar-selected-background-color-hover: $black !default;
$calendar-selected-border-color-hover: $black !default;
$calendar-selected-color-hover: $white !default;
$calendar-weekend-font-weight: bold !default;
$calendar-price-text: $gray-600 !default;
$calendar-cheapest-bg-color: lighten($brand--green, 60%) !default;
$calendar-cheapest-color: darken($brand--green, 10%) !default;
$calendar-font-size: 0.875rem !default;
$today-highlight-color: $gray-100 !default;

// Icon size values
$icon-size-xlarge: 2.5rem !default;
$icon-size-larger: 2rem !default;
$icon-size-large: 1.5rem !default;
$icon-size-medium: 1rem !default;
$icon-size-small: 0.75rem !default;
$icon-size-xsmall: 0.5rem !default;

// Icon sizes
$accordion-icon-size: $icon-size-medium !default;
$alert-icon-size: $icon-size-xlarge !default;
$basket-icon-size: $icon-size-large !default;
$booking-icon-size: $icon-size-medium !default;
$bookingLogin-icon-size: $icon-size-larger !default;
$button-icon-size: $icon-size-medium !default;
$close-icon-size: $icon-size-large !default;
$check-icon-size: $icon-size-medium !default;
$header-icon-size: $icon-size-medium !default;
$info-icon-size: $icon-size-large !default;
$nextBack-icon-size: $icon-size-medium !default;
$nextBackCardIcon-icon-size: $icon-size-medium !default;
$next-icon-size: $icon-size-medium !default;
$numberInput-icon-size: $icon-size-medium !default;
$panel-icon-size: $icon-size-medium !default;
$passwordComplexity-icon-size: $icon-size-medium !default;
$print-icon-size: $icon-size-medium !default;
$showPassword-icon-size: $icon-size-medium !default;
$skipStep-icon-size: $icon-size-medium !default;

// Icon colors
$next-icon-color: null !default;
$info-icon-color:inherit  !default;

// Forms
//
// Overrides for forms
$input-padding-x: $input-btn-padding-x !default;
$input-bg: $white !default;
$input-border-color: $gray-200 !default;
$input-disabled-bg: $color-lightest-gray !default;
$input-border-radius: $border-radius !default;
$input-group-addon-bg: $input-bg !default;
$label-margin-bottom: 0.75rem !default;

// password input tick
$form-control-is-valid-tick:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2363c05d' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") !default;

// for password show/hide input-group
$custom-border-radius-top-right: $input-border-radius !default;
$custom-border-radius-bottom-right: $input-border-radius !default;
$number-input-padding-x: $input-padding-x !default;
$input-btn-border-width: $border-width !default;

// Buttons
//
// Allows for customizing button independently from global settings
$btn-padding-y: 0.5rem !default;
$btn-padding-x: 0.75rem !default;
$btn-border-radius: 0.7rem !default;
$btn-disabled-opacity: 0.45 !default;
$btn-link-disabled-color: $gray-500 !default;
$btn-border-width: $input-btn-border-width !default;
$btn-letter-spacing: 0 !default;
$btn-font-family: $font-family-sans-serif !default;

// Button color overrides
$btn-primary-background-color: var(--primary, $primary) !default;
$btn-primary-color: var(--btn-color, $white) !default;
$btn-primary-border-color: var(--primary, $primary) !default;
$btn-primary-background-image: none !default;

$btn-primary-background-color-hover: var(--btn-primary-bg-hover, darken($primary, 5%)) !default;
$btn-primary-color-hover: var(--btn-color, $white) !default;
$btn-primary-border-color-hover: var(--btn-primary-bg-hover, darken($primary, 5%)) !default;

$btn-primary-font-family: $btn-font-family !default;
$btn-primary-text-transform: none !default;
$btn-primary-font-size: inherit !default;

$btn-primary-enable-mask: false !default;
$btn-primary-mask-url: none !default;

$btn-primary-background-color-focus: inherit !default;
$btn-primary-color-focus: inherit !default;
$btn-primary-border-color-focus: inherit !default;

$btn-outline-primary-make-solid: false !default;

$btn-secondary-background-color: var(--secondary, $secondary) !default;
$btn-secondary-background-image: none !default;
$btn-secondary-color: var(--btn-color, $white) !default;
$btn-secondary-border-color: var(--secondary, $secondary) !default;

$btn-secondary-font-family: $btn-font-family !default;
$btn-secondary-text-transform: none !default;
$btn-secondary-font-size: inherit !default;

$btn-secondary-background-color-hover: var(--btn-secondary-bg-hover, inherit) !default;
$btn-secondary-color-hover: var(--btn-color, inherit) !default;
$btn-secondary-border-color-hover: var(--btn-secondary-bg-hover, inherit) !default;

$btn-secondary-background-color-focus: $btn-secondary-background-color-hover !default;
$btn-secondary-color-focus: $btn-secondary-color-hover !default;
$btn-secondary-border-color-focus: $btn-secondary-border-color-hover !default;

$btn-secondary-enable-mask: false !default;
$btn-secondary-mask-url: none !default;

$btn-outline-secondary-border-color: $btn-secondary-border-color !default;
$btn-outline-secondary-border-color-focus: $btn-secondary-border-color-focus !default;
$btn-outline-secondary-border-color-hover: $btn-secondary-border-color-hover !default;
$btn-outline-secondary-background-color: $btn-secondary-background-color !default;
$btn-outline-secondary-background-color-focus: $btn-secondary-background-color-focus !default;
$btn-outline-secondary-background-color-hover: $btn-secondary-background-color-hover !default;
$btn-outline-secondary-color: $btn-secondary-color !default;
$btn-outline-secondary-color-focus: $btn-secondary-color-focus !default;
$btn-outline-secondary-color-hover: $btn-secondary-color-hover !default;
$btn-outline-secondary-background-image: $btn-secondary-background-image !default;

$btn-outline-secondary-make-solid: false !default;

$btn-tertiary-background-color: var(--tertiary, $secondary) !default;
$btn-tertiary-background-image: none !default;
$btn-tertiary-color: var(--btn-color, $white) !default;
$btn-tertiary-border-color: var(--tertiary, $secondary) !default;

$btn-tertiary-font-family: $btn-font-family !default;
$btn-tertiary-text-transform: none !default;

$btn-tertiary-background-color-hover: var(--btn-tertiary-bg-hover, $btn-secondary-background-color-hover) !default;
$btn-tertiary-color-hover: $btn-secondary-color-hover !default;
$btn-tertiary-border-color-hover: var(--btn-tertiary-bg-hover, $btn-secondary-border-color-hover) !default;

$btn-tertiary-background-color-focus: var(--btn-tertiary-bg-hover, $btn-secondary-background-color-hover) !default;
$btn-tertiary-color-focus: $btn-secondary-color-hover !default;
$btn-tertiary-border-color-focus: var(--btn-tertiary-bg-hover, $btn-secondary-border-color-hover) !default;

$btn-tertiary-enable-mask: false !default;
$btn-tertiary-mask-url: none !default;

$btn-disabled-color: $secondary !default;
$btn-disabled-background-color: $white !default;
$btn-disabled-border-color: $secondary !default;

$btn-additional-background-color: $secondary !default;
$btn-additional-background-image: none !default;
$btn-additional-border-color: $secondary !default;
$btn-additional-background-color-hover: $btn-secondary-background-color-hover !default;
$btn-additional-color-hover: $btn-secondary-color-hover !default;
$btn-additional-border-color-hover: $btn-secondary-border-color-hover !default;
$btn-additional-background-color-focus: $btn-secondary-background-color-hover !default;
$btn-additional-color-focus: $btn-secondary-color-hover !default;
$btn-additional-border-color-focus: $btn-secondary-border-color-hover !default;


$quantity-selector-border-radius: 50% !default;
$quantity-selector-width: 32px !default;
$quantity-selector-height: 32px !default;
$quantity-selector-background-color: $white !default;
$quantity-selector-border-color: $gray-700 !default;
$quantity-selector-border-width: 1px !default;
$quantity-selector-color: $gray-800 !default;
$btn-link-decoration: $link-decoration !default;
$btn-link-decoration-hover: $link-hover-decoration !default;
$btn-link-color-hover: $link-hover-color !default;
$btn-text-transform: inherit !default;
$quantity-selector-background-color-hover: $white !default;
$quantity-selector-border-hover: darken( $quantity-selector-border-color, 40% ) !default;
$quantity-selector-color-hover: darken( $quantity-selector-color, 40% ) !default;
$quantity-selector-background-color-focus: $quantity-selector-background-color !default;
$quantity-selector-border-focus: $quantity-selector-border-color !default;
$quantity-selector-color-focus: $quantity-selector-color !default;

// Button wrapper
$btn-wrapper-text-align: left !default;
$btn-wrapper-padding: 0 !default;
$btn-display: block !default;
$btn-width: 100% !default;

$option-list-button-width: 100% !default;
$option-list-button-background-color:$btn-secondary-background-color !default;
$option-list-button-color:$btn-secondary-color !default;
$option-list-button-border-color:$btn-secondary-border-color !default;

// Links
$link-color: $secondary !default;

// Alerts - between -10 - 10 for color strength.
$alert-bg-level: -10 !default;
$alert-border-level: 1 !default;
$alert-color-level: 3 !default;

// List Groups
$list-group-bg: $white !default;
$list-group-border-color: $gray-100 !default;
$popover-arrow-color: $gray-100 !default;

// Cards
$card-border-color: $border-color !default;
$card-border-width: 2px !default;
$card-bg: var(--card-bg, $white) !default;
$card-color: var(--card-color, inherit) !default;
$card-cap-bg: $card-bg !default;
$card-box-shadow: true !default;
$card-spacer-x: 1rem !default;
$card-font-size: $font-size-base !default;
$booking-login-card-color-hover:inherit !default;
$card-divide: solid 1px $gray-100 !default;

// themed elements
$themed-panel--active: false !default;
$themed-panel--background-color: transparent !default;
$themed-panel--background-image: none !default;
$themed-panel--background-position: auto !default;
$themed-panel--background-size: auto !default;
$themed-panel--background-repeat: auto !default;
$themed-panel--border: none !default;
$themed-panel--border-radius: 0 !default;
$themed-panel--border-image-source: none !default;
$themed-panel--border-image-repeat: auto !default;
$themed-panel--border-image-slice: auto !default;

$themed-panel--product-card--background-color: inherit !default;
$themed-panel--product-card--border: inherit !default;

/* content */
$themed-panel--color: inherit !default;
$themed-panel--padding: 0 !default;

/* selected */
$product-selected-style-active: false !default;
$product-selected-border: solid 2px $gray-100 !default;
$product-header-selected--background-color: $primary !default;
$product-header-selected--color: inherit !default;

$variant-border-bottom: $card-divide !default;

/* basket updated indicator */
$basket-updated-bg: rgba($primary, 0.2) !default;

/* product cards */
$product-card-image-mask: none !default;
$product-card-image-border-radius: $border-radius !default;

// Product specific cards
$product-card-bg: white !default;
$product-card-color: $body-color !default;
// Optional product divider line (good to use if products aren't in panels)
$product-card-divider--display: none !default;
$product-card-divider--border: 0 !default;
$product-card-divider--height: 0 !default;
$product-card-divider--padding: 0 !default;
$product-card-divider--margin: 0 !default;
$product-card-divider--background: none !default;
$product-card-divider--opacity: 0 !default;
// Products - selects
$product-selected-style-active: true !default;
$product-selected-border: solid 2px $gray-100 !default;
$product-header-selected--background-color: $white !default;
$product-header-selected--color: inherit !default;

// Panels - these are generally in 'Cards'
$panel-header-bg: var(--panel-header-bg, $gray-100) !default;
$panel-header-bg-hover: var(--panel-header-bg-hover, darken($gray-100, 5%)) !default;
$panel-header-color: var(--panel-header-color, $body-color) !default;
$panel-header-icon-color:$panel-header-color !default;
$panel-active-header-font-weight: null !default;
$panel-inactive-header-bg: $gray-100 !default;
$panel-inactive-header-bg-darken:darken($panel-inactive-header-bg, 5%) !default;
$panel-inactive-header-bg-hover: darken($gray-100, 5%) !default;
$panel-inactive-header-color: $body-color !default;
$panel-border-enabled: true !default;
$panel-border-color: $gray-100 !default;
$panel-border: solid 1px $panel-border-color !default;
$panel-border-radius: $border-radius !default;
$panel-active-box-shadow-enabled: false !default;
$panel-active-border-enabled: true !default;
$panel-active-border-color: $gray-300 !default;

// 'Active' state variables
$panel-active-border-enabled: true !default;
$panel-active-border-color: $gray-100 !default;
$panel-active-border: solid 1px $panel-active-border-color !default;
$panel-active-box-shadow-enabled: false !default;

// CheckRadio
$checkRadio-border-color: $black !default;
$checkRadio-background-color: $white !default;
$checkRadio-checked-border-color: $black !default;
$checkRadio-checked-background-color: $brand--green !default;
$checkRadio-checked-color: $white !default;

// Timeslot picker
$table-color: $body-color !default;
$timeslot-hover-bg: var(--primary, $primary) !default;
$timeslot-color: $white !default;
$timeslot-unavailable-color: $gray-400 !default;
$timeslot-border-color: $gray-100 !default;

$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;

$zindex-modal-no-backdrop: 1049 !default;
$zindex-loading: 1080 !default;

$zindex-on-top:1090 !default;

// Boostrap grid width size chart: https://getbootstrap.com/docs/4.0/layout/grid/#grid-options
// If you change the values here, then make sure to change them in app\src\components\elements\responsiveImage.jsx
$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, ) !default;

$custom-select-color: inherit !default;

$in-basket-product-border:1px solid #63c05d !default;

$status-tab-background-color:#63c05d !default;
$status-tab-color:#fff !default;
$status-tab-border:none !default;

// h1 font sizes
$h1-font-size-xl:$h1-font-size !default;
$h1-font-size-lg:$h1-font-size !default;
$h1-font-size-md:$h1-font-size !default;
$h1-font-size-sm:$h1-font-size !default;

// h1 line height
$h1-line-height-xl: $line-height-base !default;
$h1-line-height-lg: $line-height-base !default;
$h1-line-height-md: $line-height-base !default;
$h1-line-height-sm: $line-height-base !default;

// h2 font sizes
$h2-font-size-xl:$h2-font-size !default;
$h2-font-size-lg:$h2-font-size !default;
$h2-font-size-md:$h2-font-size !default;
$h2-font-size-sm:$h2-font-size !default;

// h2 line height
$h2-line-height-xl: $line-height-base !default;
$h2-line-height-lg: $line-height-base !default;
$h2-line-height-md: $line-height-base !default;
$h2-line-height-sm: $line-height-base !default;

// h3 font sizes
$h3-font-size-xl:$h3-font-size !default;
$h3-font-size-lg:$h3-font-size !default;
$h3-font-size-md:$h3-font-size !default;
$h3-font-size-sm:$h3-font-size !default;

// h3 line height
$h3-line-height-xl: $line-height-base !default;
$h3-line-height-lg: $line-height-base !default;
$h3-line-height-md: $line-height-base !default;
$h3-line-height-sm: $line-height-base !default;

// h4 font sizes
$h4-font-size-xl:$h4-font-size !default;
$h4-font-size-lg:$h4-font-size !default;
$h4-font-size-md:$h4-font-size !default;
$h4-font-size-sm:$h4-font-size !default;

// h4 line height
$h4-line-height-xl: $line-height-base !default;
$h4-line-height-lg: $line-height-base !default;
$h4-line-height-md: $line-height-base !default;
$h4-line-height-sm: $line-height-base !default;

// h5 font sizes
$h5-font-size-xl:$h5-font-size !default;
$h5-font-size-lg:$h5-font-size !default;
$h5-font-size-md:$h5-font-size !default;
$h5-font-size-sm:$h5-font-size !default;

// h5 line height
$h5-line-height-xl: $line-height-base !default;
$h5-line-height-lg: $line-height-base !default;
$h5-line-height-md: $line-height-base !default;
$h5-line-height-sm: $line-height-base !default;

$panel-inactive-header-border-color:inherit !default;

$accordion-item-border: inherit !default;

//Optional footer divider
$footer-divider--display: none !default;
$footer-card-divider--border: 0 !default;
$footer-card-divider--height: 0 !default;
$footer-card-divider--padding: 0 !default;
$footer-card-divider--margin: 0 !default;
$footer-card-divider--background: none !default;
$footer-card-divider--opacity: 0 !default;
$footer-card-divider--background-color: transparent !default;
