@import "../scss/bootstrap";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/input-group";

.input-group {
    $input-shadow-opacity: 0.25;
    border-radius: $input-border-radius;
    // hide input field focus border
    // as the appended icon breaks it
    .form-control:focus {
        outline: 0 !important;
        border-color: initial;
        box-shadow: none;
    }
    &:focus-within {
        box-shadow: 0 0 0 $input-btn-focus-width
            $input-btn-focus-color;
        outline: $input-focus-width $input-focus-box-shadow
            $input-focus-border-color;
    }
    &.is-valid {
        border: 1px solid $form-feedback-valid-color;
        &:focus-within {
            box-shadow: 0 0 0 $input-btn-focus-width
                rgba(
                    $form-feedback-valid-color,
                    $input-shadow-opacity
                );
        }
    }
    &.is-invalid {
        border: 1px solid $form-feedback-invalid-color;
        &:focus-within {
            box-shadow: 0 0 0 $input-btn-focus-width
                rgba(
                    $form-feedback-invalid-color,
                    $input-shadow-opacity
                );
        }
    }
}

/* Chrome, Safari, Edge, Opera */
input.hide-increment::-webkit-outer-spin-button,
input.hide-increment::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"].hide-increment {
    -moz-appearance: textfield;
}

.custom-switch-right {
    .custom-control-label {
        &:after,
        &:before {
            pointer-events: none;
        }
    }
}

.custom-radio {
    width: 2rem;
    height: 2rem;
}

.fieldset-item {
    display: inline-block;
    margin-right: 1rem;
    // set widths of fieldset inputs based on characters
    // e.g. .fieldset-input-3
    @for $i from 1 through 10 {
        .fieldset-input-#{$i} {
            max-width: $i * 5ex;
        }
    }
}

.input-group-append {
    .input-group-text {
        &.custom-border-radius {
            border-top-right-radius: $custom-border-radius-top-right;
            border-bottom-right-radius: $custom-border-radius-bottom-right;
        }
    }
}

.alert-arrow:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 78%;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-color: inherit;
    border-top: solid 10px;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
}

// Change Autocomplete Styles in WebKit Browsers
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: $input-border-width solid $input-border-color;
    -webkit-text-fill-color: $input-color;
    -webkit-box-shadow: 0 0 0px 1000px $input-bg inset;
    transition: background-color 5000s ease-in-out 0s;
}

.number-input {
    min-width: 130px;
    input {
        padding-left: $number-input-padding-x;
        padding-right: $number-input-padding-x;
    }
}
.custom-select.form-control {
    color: $custom-select-color;
}
.form-control.is-valid{
    background-image: $form-control-is-valid-tick;
}