﻿// global helper styles
body {
    overflow-y: overlay;
}

body,
.wrapper {
    min-height: 100vh;
}

// rule to ensure the footer  is always positioned at the bottom of the browser, regardless of the height of the content
main {
    flex: 1 0 auto;
}

.cursor-pointer {
    cursor: pointer;
}

.pe-none {
    pointer-events: none;
}

.show-expanded {
    display: none;
}

.expanded {
    .hide-expanded {
        display: none;
    }
    .show-expanded {
        display: inherit;
    }
}

.text-decoration-underline {
    text-decoration: underline;
}

.capitalize {
    text-transform: capitalize;
}

.is-loading {
    z-index: $zindex-loading;
}

.on-top {
    z-index: $zindex-on-top;
}

// responsive images should fill their container and rely on their intrinsic aspect ratio
img.responsive {
    width: 100%;
    display: block;
}

// $container-max-widths from bootstrap
@function get-breakpoints($key: "md") {
    @return map-get($container-max-widths, $key);
}

// enables setting the widths and max-widths to the bootstrap breakpoints
// e.g.
// .width-md (width fixed to the md breakpoint)
// .max-width-lg (max-width fixed at the lg breakpoint)
@each $value in (xs, sm, md, lg, xl) {
    .width-#{$value} {
        width: get-breakpoints($value);
    }
    .max-width-#{$value} {
        max-width: get-breakpoints($value);
    }
}

// modals
.modal.no-backdrop {
    z-index: $zindex-modal-no-backdrop;
}

@if $text-alignment-rule {
    // hack to fix Fira font alignment issues
    button,
    .btn {
        vertical-align: text-bottom;
        &:after {
            content: "";
            font-family: Verdana, Arial, sans-serif;
        }
    }
}
