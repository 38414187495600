﻿.accordionIcon {
    font-size: $accordion-icon-size;
}

.alertIcon {
    font-size: $alert-icon-size;
}

.basketIcon {
    font-size: $basket-icon-size;
}

.bookingIcon {
    font-size: $booking-icon-size;
}

.bookingLoginIcon {
    font-size: $bookingLogin-icon-size;
}

.buttonIcon {
    font-size: $button-icon-size;
}

.checkIcon {
    font-size: $check-icon-size;
}

.closeIcon {
    font-size: $close-icon-size;
}

.headerIcon {
    font-size: $header-icon-size;
}

.infoIcon {
    font-size: $info-icon-size;    
}
.product-card .infoIcon {
    color: $info-icon-color;
}

.nextBackIcon {
    font-size: $nextBack-icon-size;
}

.nextBackCardIcon {
    font-size: $nextBackCardIcon-icon-size;
}

.nextIcon {
    font-size: $next-icon-size;
    color: $next-icon-color;
}

.numberInputIcon {
    font-size: $numberInput-icon-size;
}

.panelIcon {
    font-size: $panel-icon-size;
}

.passwordComplexityIcon {
    font-size: $passwordComplexity-icon-size;
}

.printIcon {
    font-size: $print-icon-size;
}

.showPasswordIcon {
    font-size: $showPassword-icon-size;
}

.skipStepIcon {
    font-size: $skipStep-icon-size;
}
