﻿h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    letter-spacing: $headings-letter-spacing;
}

.page-title {
  font-family: $page-title-font-family;
  font-size: $page-title-font-size;
  color: $page-title-color;
}

.text-primary {
    color: var(--primary, $primary) !important;
}

.text-secondary {
    color: var(--secondary, $secondary) !important;
}

.font-size-base {
    font-size: $font-size-base;
}
@include media-breakpoint-down(xl) {
    h1 {
        font-size: $h1-font-size-xl;
        line-height: $h1-line-height-xl;
    }
    h2 {
        font-size: $h2-font-size-xl;
        line-height: $h2-line-height-xl;
    }
    h3 {
        font-size: $h3-font-size-xl;
        line-height: $h3-line-height-xl;
    }
    h4 {
        font-size: $h4-font-size-xl;
        line-height: $h4-line-height-xl;
    }
    h5 {
        font-size: $h5-font-size-xl;
        line-height: $h5-line-height-xl;
    }
    body{
        font-size: $font-size-xl;
        line-height: $line-height-xl;
    }
}
@include media-breakpoint-down(lg) {
    h1 {
        font-size: $h1-font-size-lg;
        line-height: $h1-line-height-lg;
    }
    h2 {
        font-size: $h2-font-size-lg;
        line-height: $h2-line-height-lg;
    }
    h3 {
        font-size: $h3-font-size-lg;
        line-height: $h3-line-height-lg;
    }
    h4 {
        font-size: $h4-font-size-lg;
        line-height: $h4-line-height-lg;
    }
    h5 {
        font-size: $h5-font-size-lg;
        line-height: $h5-line-height-lg;
    }
    body{
        font-size: $font-size-lg;
        line-height: $line-height-lg;
    }
}
@include media-breakpoint-down(md) {
    h1 {
        font-size: $h1-font-size-md;
        line-height: $h1-line-height-md;
    }
    h2 {
        font-size: $h2-font-size-md;
        line-height: $h2-line-height-md;
    }
    h3 {
        font-size: $h3-font-size-md;
        line-height: $h3-line-height-md;
    }
    h4 {
        font-size: $h4-font-size-md;
        line-height: $h4-line-height-md;
    }
    h5 {
        font-size: $h5-font-size-md;
        line-height: $h5-line-height-md;
    }
    body{
        font-size: $font-size-md;
        line-height: $line-height-md;
    }
}
@include media-breakpoint-down(sm) {
    h1 {
      font-size: $h1-font-size-sm;
      line-height: $h1-line-height-sm;
    }
    h2 {
        font-size: $h2-font-size-sm;
        line-height: $h2-line-height-sm;
    }
    h3 {
        font-size: $h3-font-size-sm;
        line-height: $h3-line-height-sm;
    }
    h4 {
        font-size: $h4-font-size-sm;
        line-height: $h4-line-height-sm;
    }
    h5 {
        font-size: $h5-font-size-sm;
        line-height: $h5-line-height-sm;
    }
    body{
        font-size: $font-size-sm;
        line-height: $line-height-sm;
    }
}
