﻿@mixin button-hover-variant(
    $hover-background,
    $hover-color,
    $hover-border
) {
    &:hover,
    &:active {
        background-color: $hover-background;
        color: $hover-color;
        border-color: $hover-border;
        background-image: none;
    }
}

.btn-primary,
.btn-primary:not(:disabled):not(.disabled) {
    background-color: $btn-primary-background-color;
    color: $btn-primary-color;
    border-color: $btn-primary-border-color;
    font-family: $btn-primary-font-family;
    text-transform: $btn-primary-text-transform;
    font-size: $btn-primary-font-size;
    background-image: $btn-primary-background-image;

    @if $btn-primary-enable-mask {
        mask-image: $btn-primary-mask-url;
    }
    &:focus {
        background-color: $btn-primary-background-color-focus;
        color: $btn-primary-color-focus;
        border-color: $btn-primary-border-color-focus;
        box-shadow: none;
        background-image: none;
    }

    @include button-hover-variant( $btn-primary-background-color-hover, $btn-primary-color-hover, $btn-primary-border-color-hover );
}

@if $btn-outline-primary-make-solid {
    .btn-outline-primary,
    .btn-outline-primary:not(:disabled):not(.disabled) {
        background-color: $btn-primary-background-color;
        color: $btn-primary-color;
        border-color: $btn-primary-border-color;
        font-family: $btn-primary-font-family;
        text-transform: $btn-primary-text-transform;
        font-size: $btn-primary-font-size;

        @if $btn-primary-enable-mask {
            mask-image: $btn-primary-mask-url;
        }

        @include button-hover-variant( $btn-primary-background-color-hover, $btn-primary-color-hover, $btn-primary-border-color-hover );
    }
} @else {
    .btn-outline-primary,
    .btn-outline-primary:not(:disabled):not(.disabled) {
        border-color: $btn-primary-border-color;

        @include button-hover-variant( $btn-primary-background-color-hover, $btn-primary-color-hover, $btn-primary-border-color-hover );
    }
}

.option-list-button {
    display: block;
    width: $option-list-button-width;
    margin-bottom: 0.5rem;
    background-color: $option-list-button-background-color;
    color: $option-list-button-color;
    border-color: $option-list-button-border-color;
}

.btn-secondary,
.btn-secondary:not(:disabled):not(.disabled) {
    background-color: $btn-secondary-background-color;
    color: $btn-secondary-color;
    border-color: $btn-secondary-border-color;
    font-family: $btn-secondary-font-family;
    text-transform: $btn-secondary-text-transform;
    font-size: $btn-secondary-font-size;
    background-image: $btn-secondary-background-image;

    @if $btn-secondary-enable-mask {
        mask-image: $btn-secondary-mask-url;
    }
    &:focus {
        background-color: $btn-secondary-background-color-focus;
        color: $btn-secondary-color-focus;
        border-color: $btn-secondary-border-color-focus;
        box-shadow: none;
        background-image: none;
    }

    @include button-hover-variant( $btn-secondary-background-color-hover, $btn-secondary-color-hover, $btn-secondary-border-color-hover );
}

@if $btn-outline-secondary-make-solid {
    .btn-outline-secondary,
    .btn-outline-secondary:not(:disabled):not(.disabled) {
        background-color: $btn-secondary-background-color;
        color: $btn-secondary-color;
        border-color: $btn-secondary-border-color;
        font-family: $btn-secondary-font-family;
        text-transform: $btn-secondary-text-transform;
        font-size: $btn-secondary-font-size;
        background-image: $btn-secondary-background-image;

        @if $btn-secondary-enable-mask {
            mask-image: $btn-secondary-mask-url;
        }
        &:focus {
            background-color: $btn-secondary-background-color-focus;
            color: $btn-secondary-color-focus;
            border-color: $btn-secondary-border-color-focus;
            box-shadow: none;
            background-image: none;
        }

        @include button-hover-variant( $btn-secondary-background-color-hover, $btn-secondary-color-hover, $btn-secondary-border-color-hover );
    }
} @else {
    .btn-outline-secondary,
    .btn-outline-secondary:not(:disabled):not(.disabled) {
        border-color: $btn-outline-secondary-border-color;
        background-color: $btn-outline-secondary-background-color;
        color: $btn-outline-secondary-color;
        background-image: $btn-outline-secondary-background-image;

        &:focus {
            background-color: $btn-outline-secondary-background-color-focus;
            color: $btn-outline-secondary-color-focus;
            border-color: $btn-outline-secondary-border-color-focus;
            box-shadow: none;
            background-image: none;
        }

        @include button-hover-variant( $btn-outline-secondary-background-color-hover, $btn-outline-secondary-color-hover, $btn-outline-secondary-border-color-hover );
    }
}

.btn-tertiary {
    background-color: $btn-tertiary-background-color;
    color: $btn-tertiary-color;
    border-color: $btn-tertiary-border-color;
    font-family: $btn-tertiary-font-family;
    text-transform: $btn-tertiary-text-transform;
    background-image: $btn-tertiary-background-image;

    @if $btn-tertiary-enable-mask {
        mask-image: $btn-tertiary-mask-url;
    }
    &:focus {
        background-color: $btn-tertiary-background-color-focus;
        color: $btn-tertiary-color-focus;
        border-color: $btn-tertiary-border-color-focus;
        box-shadow: none;
        background-image: none;
    }
}

.btn-tertiary,
.btn-tertiary:not(:disabled):not(.disabled) {
    @include button-hover-variant( $btn-tertiary-background-color-hover, $btn-tertiary-color-hover, $btn-tertiary-border-color-hover );
}

.quantity-selector-btn {
    font-size: 1rem;
    border: $quantity-selector-border-width solid $quantity-selector-border-color;
    border-radius: $quantity-selector-border-radius;
    width: $quantity-selector-width;
    height: $quantity-selector-height;
    color: $quantity-selector-color;
    background: $quantity-selector-background-color;
    flex: 0 0 auto;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:disabled {
        opacity: 0.4;
    }

    &:not(:disabled):not(.disabled) {
        &:hover,
        &:active {
            border: 1px solid $quantity-selector-border-hover;
            color: $quantity-selector-color-hover;
            background: $quantity-selector-background-color-hover;
        }
    }

    &:focus {
        outline: none;
        border: 1px solid $quantity-selector-border-focus;
        color: $quantity-selector-color-focus;
        background: $quantity-selector-background-color-focus;
    }
}

.btn-link {
    text-decoration: $btn-link-decoration;

    &:hover,
    &:active {
        color: $btn-link-color-hover;
        text-decoration: $btn-link-decoration-hover;
    }
}

@include media-breakpoint-down(xs) {
    .btn-block-xs {
        display: block;
        width: 100%;
        // Vertically space out multiple block buttons
        + .btn-block {
            margin-top: $btn-block-spacing-y;
        }
    }
}

.btn:not(.btn-link) {
    letter-spacing: $btn-letter-spacing;
}

.button-wrapper {
    text-align: $btn-wrapper-text-align;
    padding: $btn-wrapper-padding;
    .btn:not(.btn-link) {
        display: $btn-display;
        width: $btn-width;
    }
}

.btn-additional {
    background-image: $btn-additional-background-image;
    @include button-variant(
        $btn-additional-background-color,
        $btn-additional-border-color
    );
}

.btn-additional,
.btn-additional:not(:disabled):not(.disabled) {
    &:focus {
        background-color: $btn-additional-background-color-focus;
        color: $btn-additional-color-focus;
        border-color: $btn-additional-border-color-focus;
        box-shadow: none;
        background-image: none;
    }
    @include button-hover-variant( $btn-additional-background-color-hover, $btn-additional-color-hover, $btn-additional-border-color-hover );
}

.btn {
    cursor: pointer;
    &:disabled {
        cursor: not-allowed;
        color: $btn-disabled-color;
        background-color: $btn-disabled-background-color;
        border-color: $btn-disabled-border-color;
        background-image: none;
    }

    &:not(.btn-link) {
        text-transform: $btn-text-transform;
    }
}
