﻿// Client specific variables live here i.e. this should only have content in client branches
// Use this file to change teh client site's appearance by overriding the default variables in
//  _variables-vanilla.scss or by overriding boostrap's default variables.
$spacer: 1.2rem;

// Grays
$white: #fff;
$gray-100: #e1e2e4;
$gray-200: #c3c6ca;
$gray-300: #a6a9af;
$gray-400: #888d95;
$gray-500: #6a707a;
$gray-600: #555a62;
$gray-700: #404349;
$gray-800: #2a2d31;
$gray-900: #151618;
$black: #000;
$color-lightest-gray: #f6f6f7;

// Brand colours
$brand--green: #003D4C;

$brand--lightest-gray: #f9f9f9;
$brand--lighter-gray: #efefef;
$brand-gray: $gray-800;
$brand-dark-gray: #666;


// Override Bootstrap colors
$red: #b30415;
$green: $brand--green;
$primary: $brand--green;
$secondary: $brand-gray;

$success: $green;
$warning: $red;
$danger: $red;

// Body
//
// Settings for the `<body>` element.
$body-bg: $brand--lightest-gray;
$body-color: $brand-dark-gray;

// Typography
$custom-fonts-url: "https://use.typekit.net/meh5eap.js";
$font-family-sans-serif: 'proxima-nova', sans-serif;
$headings-font-family: $font-family-sans-serif;
$font-size-base: 1.1rem;

$line-height-base: 1.5;

$h1-font-size: 2.8rem;
$h2-font-size: 2rem;
$h3-font-size: 1.75rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1rem;

// Page title font size for mobile devices
$h1-font-size-md: 2.4rem;
$h1-font-size-sm: 2.4rem;

$headings-font-weight: 600;
$headings-color: $brand--green;
$headings-letter-spacing: inherit;

$page-title-font-family: 'kepler-std-semicondensed', serif;
$page-title-font-size: null;
$page-title-color: $headings-color;


// Header
//
// Settings for the Header area
$top-bar-text-transform: inherit;
$top-bar-bg: $white;
$top-bar-color: inherit;
$top-bar-link-color: inherit;
$top-bar-link-hover-color: inherit;
$top-bar-border-bottom: solid 1px $gray-100;
$top-bar-icon-color: inherit;
// Header
$header-bg: var(--header-bg, $brand--green);
$header-bg-2: var(--header-bg-2, $brand--green);
$header-border-bottom: solid 1px $gray-100;
$header-shadow: false;
// logo
$header-logo-width: 180px;
$header-logo-height: 75px;
// login bar
$login-bar-text-transform: uppercase;
$login-bar-bg: $color-lightest-gray;
$login-bar-color: inherit;
$login-bar-link-color: inherit;
$login-bar-link-hover-color: inherit;
$login-bar-border-bottom: solid 1px $gray-100;
$login-bar-icon-color: inherit;
// popover
$popover-arrow-color: $gray-100;
// basket icon
$basket-icon-color: white;
$basket-icon-color-hover: white;
// basket badge
$basket-badge-background: $white;
$basket-badge-color: $secondary;
$basket-badge-padding: 1px 2px 2px 2px;

// Footer
//
// Settings for the Footer area
$footer-bg: $brand--green;
$footer-color: white;
$footer-border-top: solid 1px $gray-300;
$footer-bar-bg: darken($brand--green, 5%);
$footer-bar-color: var(--footer-bar-color, $white);
$footer-bar-logo-mw: 100px;
$footer-bar-text-transform: inherit;

// Links
$link-color: $secondary;
$link-decoration: underline 1px dotted;
$link-hover-color: darken($link-color, 35%);
$link-hover-decoration: none;

// Components
//
// Define common padding and border radius sizes and more.
$border-width: 1px;
$border-color: $brand--lighter-gray;
$border-radius: 0!default;
$border-radius-lg: $border-radius;
$border-radius-sm: 0;

// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-padding-y: 0.375rem;
$input-btn-padding-x: 0.75rem;


// Icon colors
$next-icon-color: null;

// Forms
//
// Overrides for forms
$input-padding-x: $input-btn-padding-x;
$input-bg: $white;
$input-border-color: $gray-200;
$input-disabled-bg: $color-lightest-gray;
$input-border-radius: 0;
$input-group-addon-bg: $input-bg;
$label-margin-bottom: 0.75rem;

// for password show/hide input-group
$custom-border-radius-top-right: $input-border-radius;
$custom-border-radius-bottom-right: $input-border-radius;
$number-input-padding-x: $input-padding-x;
$input-btn-border-width: $border-width;

// Buttons
//
// Allows for customizing button independently from global settings
$btn-padding-y: 0.5rem;
$btn-padding-x: 0.75rem;
$btn-border-radius: 3rem;
$btn-disabled-opacity: 0.45;
$btn-border-width: $input-btn-border-width;
$btn-letter-spacing: 0;
$btn-link-disabled-color: $brand--lighter-gray;
$btn-border-radius-sm: 1rem;

// Button color overrides
$btn-primary-background-color-hover: $white;
$btn-primary-color-hover: $primary;
$btn-primary-border-color-hover: $primary;

$btn-secondary-background-color-hover: inherit;
$btn-secondary-color-hover: inherit;
$btn-secondary-border-color-hover: inherit;

$btn-tertiary-background-color: $secondary;
$btn-tertiary-border-color: $secondary;
$btn-tertiary-background-color-hover: $btn-secondary-background-color-hover;
$btn-tertiary-color-hover: $btn-secondary-color-hover;
$btn-tertiary-border-color-hover: $btn-secondary-border-color-hover;

$btn-disabled-color: $secondary;
$btn-disabled-background-color: $white;
$btn-disabled-border-color: $secondary;

$btn-additional-background-color: $secondary;
$btn-additional-border-color: $secondary;
$btn-additional-background-color-hover: $btn-secondary-background-color-hover;
$btn-additional-color-hover: $btn-secondary-color-hover;
$btn-additional-border-color-hover: $btn-secondary-border-color-hover;

// Button wrapper
$btn-wrapper-text-align: left;
$btn-wrapper-padding: 0;
$btn-display: block;
$btn-width: 100%;

// Links
$link-color: $secondary;

// List Groups
$list-group-bg: $white;
$list-group-border-color: $gray-100;
$popover-arrow-color: $gray-100;

// Cards
$card-border-color: $border-color;
$card-border-width: 1px;
$card-bg: $white;
$card-color: $brand-dark-gray;
$card-cap-bg: $card-bg;
$card-box-shadow: false;
$card-spacer-x: 1rem;
$card-font-size: $font-size-base;


/* selected */
$product-selected-style-active: false;
$product-selected-border: solid 2px $gray-100;
$product-header-selected--background-color: $primary;
$product-header-selected--color: inherit;

$variant-border-bottom: solid 1px $gray-100;

/* basket updated indicator */
$basket-updated-bg: rgba($primary, 0.2);

/* product cards */
$product-card-image-mask: none;

// Product specific cards
$product-card-bg: $card-bg;
$product-card-panel-bg: $card-bg;
// Optional product divider line (good to use if products aren't in panels)
$product-card-divider--display: none;
$product-card-divider--border: 0;
$product-card-divider--height: 0;
$product-card-divider--padding: 0;
$product-card-divider--margin: 0;
$product-card-divider--background: none;
$product-card-divider--opacity: 0;
// Products - selects
$product-selected-style-active: true;
$product-selected-border: solid 2px $gray-100;
$product-header-selected--background-color: $white;
$product-header-selected--color: inherit;

// Panels - these are generally in 'Cards'
$panel-header-bg: $brand--lighter-gray;
$panel-header-color: $body-color;
$panel-active-header-font-weight: null;
$panel-inactive-header-bg: $gray-100;
$panel-inactive-header-color: $body-color;
$panel-border-enabled: true;
$panel-border-color: $gray-100;
$panel-border: solid 1px $panel-border-color;
$panel-border-radius: $border-radius;
$panel-active-box-shadow-enabled: false;
$panel-active-border-enabled: true;
$panel-active-border-color: $gray-300;

$status-tab-background-color: $brand--green;

$btn-outline-secondary-background-color: transparent;
$btn-outline-secondary-color: $gray-800;
$btn-outline-secondary-background-color-focus: inherit;
$btn-outline-secondary-color-focus: inherit;
$btn-outline-secondary-border-color-focus: inherit;
$btn-outline-secondary-background-color-hover: inherit;
$btn-outline-secondary-color-hover: inherit;
$btn-outline-secondary-border-color-hover: inherit;

$option-list-button-background-color: $btn-outline-secondary-background-color;
$option-list-button-color: $btn-outline-secondary-color;
$option-list-button-border-color: $btn-outline-secondary-color-hover;
